import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import ReactGA from "react-ga4";

import { ReactComponent as ClockIcon } from "../assets/icons/clock.svg";
import { ReactComponent as CompanyIcon } from "../assets/icons/company.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/email.svg";
import { ReactComponent as LocationIcon } from "../assets/icons/location.svg";
import { ReactComponent as UserIcon } from "../assets/icons/user.svg";
import Button from "../components/Button";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import SelectCustom from "../components/SelectCustom";
import Header from "../components/Header";

const HomepageEnglish = (props) => {
  const text = require(`../Text.json`);
  const refTop = useRef(null);
  const refAboutMe = useRef(null);
  const refApproach = useRef(null);
  const refContact = useRef(null);
  const refPreviousWork = useRef(null);
  let navigate = useNavigate();
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [buttonText, setButtonText] = useState(
    text["contact-form-button"][props.language]
  );
  const [contact, setContact] = useState({
    kind: "",
    name: "",
    company: "",
    email: "",
    message: "",
  });
  const options = [
    {
      label: text["contact-form-kind-first-option"][props.language],
      value: text["contact-form-kind-first-option-value"][props.language],
    },
    {
      label: text["contact-form-kind-second-option"][props.language],
      value: text["contact-form-kind-second-option-value"][props.language],
    },
    {
      label: text["contact-form-kind-third-option"][props.language],
      value: text["contact-form-kind-third-option-value"][props.language],
    },
  ];

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/${props.language}`,
      title: `Homepage ${props.language}`,
    });
  }, []);

  const executeScroll = (element) => {
    element.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleValueChange = (label, value) => {
    var tempContact = { ...contact };
    tempContact[label] = value;
    setContact(tempContact);
    setButton(tempContact);
    setButtonText(text["contact-form-button"][props.language]);
  };

  const initContact = () => {
    setContact({
      name: "",
      company: "",
      email: "",
      message: "",
    });
  };

  const sendEmail = (e) => {
    if (buttonEnabled) {
      ReactGA.event({
        name: "Email enabled click",
        category: "User",
        action: "Clicked send email enabled",
      });

      e.preventDefault();

      emailjs.send(
        "service_5sd3dwd",
        "template_6fpdm5p",
        contact,
        "1osQAwSmqIZrkOibl"
      );

      initContact();
      setButtonText(text["contact-form-button-confirmation"][props.language]);
      setButtonEnabled(false);
    } else {
      ReactGA.event({
        name: "Email disabled click",
        category: "User",
        action: "Clicked send email disabled",
      });
    }
  };

  const setButton = (contact) => {
    if (Object.values(contact).some((value) => value === "")) {
      setButtonEnabled(false);
    } else {
      setButtonEnabled(true);
    }
  };

  return (
    <div>
      <Header
        executeScroll={executeScroll}
        language={props.language}
        refAboutMe={refAboutMe}
        refApproach={refApproach}
        refContact={refContact}
        refPreviousWork={refPreviousWork}
        refTop={refTop}
      />
      <div className="welcome-banner-container">
        <div className="welcome-banner" ref={refTop}>
          <div className="welcome-banner-headline">
            {text["welcome-banner-headline"][props.language]}
          </div>
          <div className="welcome-banner-text mobile">
            <div className="welcome-banner-text-paragraph">
              {text["welcome-banner-mobile-first-paragraph"][props.language]}
            </div>
          </div>
          <div className="welcome-banner-text">
            <div className="welcome-banner-text-paragraph">
              {text["welcome-banner-first-paragraph"][props.language]}
            </div>
            <div className="welcome-banner-text-paragraph">
              {text["welcome-banner-second-paragraph"][props.language]}
            </div>
            <div className="welcome-banner-text-paragraph">
              {text["welcome-banner-third-paragraph"][props.language]}
            </div>
          </div>
          <div className="welcome-banner-button">
            <Button
              onClick={() => {
                ReactGA.event({
                  name: "Welcome Button click",
                  category: "User",
                  action: "Clicked welcome-banner-button",
                });
                executeScroll(refContact);
              }}
            >
              {text["welcome-banner-button"][props.language]}
            </Button>
          </div>
        </div>
        <div
          className="welcome-banner-arrow"
          onClick={() => executeScroll(refAboutMe)}
        >
          &darr;
        </div>
      </div>
      <div className="about-me-container">
        <div className="about-me">
          <div className="about-me-headline" ref={refAboutMe}>
            Till Stratmann
          </div>
          <div className="about-me-content">
            <div className="about-me-content-text">
              <div className="about-me-content-text-paragraph-first">
                {text["about-me-content-first-paragraph"][props.language]}
              </div>
              <div className="about-me-content-text-paragraph">
                {text["about-me-content-second-paragraph"][props.language]}
              </div>
              <div className="about-me-content-text-paragraph">
                {text["about-me-content-third-paragraph"][props.language]}
              </div>
            </div>
            <div className="about-me-content-card">
              <div className="about-me-content-pic">
                <img src={require("../assets/picture_till_2.jpg")}></img>
              </div>
              <div className="about-me-socials">
                <a
                  href="https://www.linkedin.com/in/tstratmann"
                  target="_blank"
                >
                  <div className="contact-card-social">
                    <div className="contact-card-social-icon">
                      <img src={require("../assets/icons/linkedin.png")}></img>
                    </div>
                    <div className="about-me-social-text">tstratmann</div>
                  </div>
                </a>
                <a
                  href="mailto:till@tillstratmann.com?subject=Inquiry from website"
                  target="_blank"
                >
                  <div className="contact-card-social">
                    <div className="contact-card-social-icon">
                      <EmailIcon className="icon" />
                    </div>
                    <div className="about-me-social-text">
                      till@tillstratmann.com
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="about-me-logos">
            <div className="about-me-logos-slide">
              <img src={require("../assets/logo_goldbeck.png")}></img>
              <img src={require("../assets/logo_cambridge.png")}></img>
              <img src={require("../assets/logo_porsche.png")}></img>
              <img src={require("../assets/logo_kit.png")}></img>
              <img src={require("../assets/logo_dräger.png")}></img>
              <img src={require("../assets/logo_purdue.png")}></img>
              <img src={require("../assets/logo_goldbeck.png")}></img>
              <img src={require("../assets/logo_cambridge.png")}></img>
              <img src={require("../assets/logo_porsche.png")}></img>
              <img src={require("../assets/logo_kit.png")}></img>
              <img src={require("../assets/logo_dräger.png")}></img>
              <img src={require("../assets/logo_purdue.png")}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="approach-container">
        <div className="approach">
          <div className="approach-headline" ref={refApproach}>
            {text["approach-headline"][props.language]}
          </div>
          <div className="approach-content-left">
            <div className="approach-content-subheading">
              {text["approach-first-subheading"][props.language]}
            </div>
            <div className="approach-content-text">
              <div className="approach-content-text-paragraph">
                {text["approach-second-paragraph"][props.language]}
              </div>
            </div>
            <div className="approach-graphic">
              <div className="approach-graph-container">
                <div className="approach-graph-axis"></div>
                <div className="approach-graph-description">
                  <div className="approach-graph-description-padding"></div>
                  <div className="approach-graph-description-text">
                    Operations
                  </div>
                  <div className="approach-graph-labels">
                    <div className="approach-graph-label">
                      <div className="approach-graph-label-text">
                        {
                          text["approach-graph-label-procurement"][
                            props.language
                          ]
                        }
                      </div>
                    </div>
                    <div className="approach-graph-label">
                      <div className="approach-graph-label-text">
                        {text["approach-graph-label-hr"][props.language]}
                      </div>
                    </div>
                    <div className="approach-graph-label">
                      <div className="approach-graph-label-text">
                        {
                          text["approach-graph-label-production"][
                            props.language
                          ]
                        }
                      </div>
                    </div>
                    <div className="approach-graph-label">
                      <div className="approach-graph-label-text">
                        {text["approach-graph-label-sales"][props.language]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="approach-graph-columns">
                  <div className="approach-graph-column procurement"></div>
                  <div className="approach-graph-column hr"></div>
                  <div className="approach-graph-column production"></div>
                  <div className="approach-graph-column sales"></div>
                  <div className="approach-graph-column profit">
                    {text["approach-graph-label-profit"][props.language]}
                  </div>
                </div>
              </div>
              <div className="approach-solutions-container">
                <div className="approach-solutions-subcontainer">
                  <div className="approach-solutions-label">
                    {text["approach-solutions-label-challenge"][props.language]}
                  </div>
                  <div className="approach-solutions-text">
                    <div className="approach-solutions-challenges">
                      <div className="approach-solutions-solution">&nbsp;</div>
                      <div className="approach-solutions-solution">
                        {
                          text["approach-solutions-challenge-procurement"][
                            props.language
                          ]
                        }
                      </div>
                      <div className="approach-solutions-solution">
                        {
                          text["approach-solutions-challenge-production"][
                            props.language
                          ]
                        }
                      </div>
                      <div className="approach-solutions-solution">
                        {
                          text["approach-solutions-challenge-hr"][
                            props.language
                          ]
                        }
                      </div>
                      <div className="approach-solutions-solution">
                        {
                          text["approach-solutions-challenge-sales"][
                            props.language
                          ]
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="approach-solutions-subcontainer arrow">
                  <div className="approach-solutions-label">&nbsp;</div>
                  <div className="approach-solutions-arrow">&rarr;</div>
                </div>
                <div className="approach-solutions-subcontainer">
                  <div className="approach-solutions-label">
                    {text["approach-solutions-label-solution"][props.language]}
                  </div>
                  <div className="approach-solutions-text">
                    <div className="approach-solutions-solutions">
                      <div className="approach-solutions-solution">&nbsp;</div>
                      <div className="approach-solutions-solution">&nbsp;</div>
                      <div className="approach-solutions-solution">
                        {
                          text["approach-solutions-solution-procurement"][
                            props.language
                          ]
                        }
                      </div>
                      <div className="approach-solutions-solution">&nbsp;</div>
                      <div className="approach-solutions-solution">
                        {
                          text["approach-solutions-solution-production"][
                            props.language
                          ]
                        }
                      </div>
                      <div className="approach-solutions-solution">&nbsp;</div>
                      <div className="approach-solutions-solution">
                        {text["approach-solutions-solution-hr"][props.language]}
                      </div>
                      <div className="approach-solutions-solution">&nbsp;</div>
                      <div className="approach-solutions-solution">
                        {
                          text["approach-solutions-solution-sales"][
                            props.language
                          ]
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="approach-content-right">
            <div className="approach-content-subheading">
              {text["approach-second-subheading"][props.language]}
            </div>
            <div className="approach-content-text right">
              <div className="approach-content-text-paragraph">
                {text["approach-sixth-paragraph"][props.language]}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="previous-work-container">
    <div className="previous-work">
      <div className="previous-work-headline" ref={refPreviousWork}>
        Previous work
      </div>
      <div className="previous-work-example">
        <div className="previous-work-example-pic">
          <img src={require("../assets/example_camden_boss_2.png")}></img>
        </div>
        <div className="previous-work-example-description">
          <div className="previous-work-example-description-title">
            Order tracking
          </div>
          <div className="previous-work-example-description-bullets">
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <CompanyIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                CamdenBoss Ltd.
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <LocationIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                Mildenhall, UK
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <ClockIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                2 weeks
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <UserIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                Teamwork, two people
              </div>
            </div>
          </div>
          <div className="previous-work-example-description-text">
            <div className="previous-work-example-description-text-paragraph">
              CamdenBoss was struggling to track their orders on the shop
              floor. They did not know the location nor the process step
              an order was at.
            </div>
            <div className="previous-work-example-description-text-paragraph">
              My course mate Paco and I developed order tracking software
              that integrated seemlessly with ERP software that was
              already being used.
            </div>
          </div>
        </div>
      </div>
      <div className="previous-work-testimonial">
        <div className="previous-work-testimonial-text">
          <div className="previous-work-testimonial-quote">
            "Paco and Till have done fabulous work for CamdenBoss. We're
            already getting the workstations set up in a trial area while
            we form our rollout strategy. What they have done is nothing
            short of amazing."
          </div>
          <div className="previous-work-testimonial-name-container">
            Katy Davies, Managing Director, CamdenBoss
          </div>
        </div>
        <div className="previous-work-testimonial-pic">
          <img src={require("../assets/katy_davies.jpg")}></img>
        </div>
      </div>
      <br />
      <div className="previous-work-example">
        <div className="previous-work-example-description">
          <div className="previous-work-example-description-title">
            Asset management
          </div>
          <div className="previous-work-example-description-bullets">
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <CompanyIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                EWS LLS
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <LocationIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                Doha, QA
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <ClockIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                2 weeks
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <UserIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                Individual work
              </div>
            </div>
          </div>
          Development of a Portal Lorwm Ipsum Dolor sit amet
        </div>
        <div className="previous-work-example-pic">
          <img src={require("../assets/example_ews_2.png")}></img>
        </div>
      </div>
      <div className="previous-work-testimonial">
        <div className="previous-work-testimonial-pic">
          <img src={require("../assets/alexander_hauenschild.jpeg")}></img>
        </div>
        <div className="previous-work-testimonial-text">
          <div className="previous-work-testimonial-quote">
            "Paco and Till have done fabulous work for CamdenBoss. We're
            already getting the workstations set up in a trial area while
            we form our rollout strategy. What they have done is nothing
            short of amazing."
          </div>
          <div className="previous-work-testimonial-name-container">
            Alexander Hauenschild, Partner, WMC International
          </div>
        </div>
      </div>
      <br />
      <div className="previous-work-example">
        <div className="previous-work-example-pic">
          <img src={require("../assets/example_rontec_2.png")}></img>
        </div>
        <div className="previous-work-example-description">
          <div className="previous-work-example-description-title">
            Offer management
          </div>
          <div className="previous-work-example-description-bullets">
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <CompanyIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                rontec GmbH
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <LocationIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                Denkendorf, DE
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <ClockIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                3 weeks
              </div>
            </div>
            <div className="previous-work-example-description-bullet">
              <div className="previous-work-example-description-bullet-icon">
                <UserIcon className="icon" />
              </div>
              <div className="previous-work-example-description-bullet-text">
                Individual work
              </div>
            </div>
          </div>
          <div className="previous-work-example-description-text">
            <div className="previous-work-example-description-text-paragraph">
              CamdenBoss was struggling to track their orders on the shop
              floor. They did not know the location nor the process step
              an order was at.
            </div>
            <div className="previous-work-example-description-text-paragraph">
              My course mate Paco and I developed order tracking software
              that integrated seemlessly with ERP software that was
              already being used.
            </div>
          </div>
        </div>
      </div>
      <div className="previous-work-testimonial">
        <div className="previous-work-testimonial-text">
          <div className="previous-work-testimonial-quote">
            "Paco and Till have done fabulous work for CamdenBoss. We're
            already getting the workstations set up in a trial area while
            we form our rollout strategy. What they have done is nothing
            short of amazing."
          </div>
          <div className="previous-work-testimonial-name-container">
            Markus Röser, Managing Director, rontec
          </div>
        </div>
        <div className="previous-work-testimonial-pic">
          <img src={require("../assets/markus_röser.jpeg")}></img>
        </div>
      </div>
    </div>
  </div>*/}
      <div className="previous-work-subcontainer">
        <div className="previous-work-header">
          <div className="previous-work-headline-background">
            <div className="previous-work-headline" ref={refPreviousWork}>
              {text["previous-work-headline"][props.language]}
            </div>
          </div>
        </div>
        <div className="previous-work-background">
          <div className="previous-work-background-column">
            <div className="previous-work-example">
              <div className="previous-work-example-pic">
                <img src={require("../assets/example_rontec_2.png")}></img>
              </div>
              <div className="previous-work-example-description">
                <div className="previous-work-example-description-title">
                  {text["previous-work-first-example"][props.language]}
                </div>
                <div className="previous-work-example-description-bullets">
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <CompanyIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      rontec GmbH
                    </div>
                  </div>
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <LocationIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      Denkendorf, DE
                    </div>
                  </div>
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <ClockIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      {text["previous-work-first-duration"][props.language]}
                    </div>
                  </div>
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <UserIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      {text["previous-work-first-people"][props.language]}
                    </div>
                  </div>
                </div>
                <div className="previous-work-example-description-text">
                  <div className="previous-work-example-description-text-paragraph">
                    {
                      text["previous-work-first-first-paragraph"][
                        props.language
                      ]
                    }
                  </div>
                  <div className="previous-work-example-description-text-paragraph">
                    {
                      text["previous-work-first-second-paragraph"][
                        props.language
                      ]
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="previous-work-testimonial">
              <div className="previous-work-testimonial-text">
                <div className="previous-work-testimonial-quote">
                  {text["previous-work-first-testimonial"][props.language]}
                </div>
                <div className="previous-work-testimonial-name-container">
                  <div className="previous-work-testimonial-pic-small">
                    <img src={require("../assets/markus_röser.jpeg")}></img>
                  </div>
                  <div className="previous-work-testimonial-name">
                    <div>Markus Röser,&nbsp;</div>
                    {
                      text["previous-work-first-testimonial-role"][
                        props.language
                      ]
                    }
                  </div>
                </div>
              </div>
              <div className="previous-work-testimonial-pic">
                <img src={require("../assets/markus_röser.jpeg")}></img>
              </div>
            </div>
          </div>
        </div>
        <div className="previous-work-background shadow">
          <div className="previous-work-background-column">
            <div className="previous-work-example reverse">
              <div className="previous-work-example-description">
                <div className="previous-work-example-description-title">
                  {text["previous-work-second-example"][props.language]}
                </div>
                <div className="previous-work-example-description-bullets">
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <CompanyIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      EWS LLS
                    </div>
                  </div>
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <LocationIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      Doha, QA
                    </div>
                  </div>
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <ClockIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      {text["previous-work-second-duration"][props.language]}
                    </div>
                  </div>
                  <div className="previous-work-example-description-bullet">
                    <div className="previous-work-example-description-bullet-icon">
                      <UserIcon className="icon" />
                    </div>
                    <div className="previous-work-example-description-bullet-text">
                      {text["previous-work-second-people"][props.language]}
                    </div>
                  </div>
                </div>
                <div className="previous-work-example-description-text-paragraph">
                  {text["previous-work-second-first-paragraph"][props.language]}
                </div>
                <div className="previous-work-example-description-text-paragraph">
                  {
                    text["previous-work-second-second-paragraph"][
                      props.language
                    ]
                  }
                </div>
              </div>
              <div className="previous-work-example-pic">
                <img src={require("../assets/example_ews_2.png")}></img>
              </div>
            </div>
            <div className="previous-work-testimonial">
              <div className="previous-work-testimonial-pic">
                <img
                  src={require("../assets/alexander_hauenschild.jpeg")}
                ></img>
              </div>
              <div className="previous-work-testimonial-text">
                <div className="previous-work-testimonial-quote">
                  {text["previous-work-second-testimonial"][props.language]}
                </div>
                <div className="previous-work-testimonial-name-container">
                  <div className="previous-work-testimonial-pic-small">
                    <img
                      src={require("../assets/alexander_hauenschild.jpeg")}
                    ></img>
                  </div>
                  <div className="previous-work-testimonial-name">
                    <div>Alexander Hauenschild,&nbsp;</div>
                    {
                      text["previous-work-second-testimonial-role"][
                        props.language
                      ]
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="previous-work-background shadow last">
        <div className="previous-work-background-column last">
          <div className="previous-work-example">
            <div className="previous-work-example-pic">
              <img src={require("../assets/example_camden_boss_2.png")}></img>
            </div>
            <div className="previous-work-example-description">
              <div
                className="previous-work-example-description-title"
                lang={props.language}
              >
                {text["previous-work-third-example"][props.language]}
              </div>
              <div className="previous-work-example-description-bullets">
                <div className="previous-work-example-description-bullet">
                  <div className="previous-work-example-description-bullet-icon">
                    <CompanyIcon className="icon" />
                  </div>
                  <div className="previous-work-example-description-bullet-text">
                    CamdenBoss Ltd.
                  </div>
                </div>
                <div className="previous-work-example-description-bullet">
                  <div className="previous-work-example-description-bullet-icon">
                    <LocationIcon className="icon" />
                  </div>
                  <div className="previous-work-example-description-bullet-text">
                    Mildenhall, UK
                  </div>
                </div>
                <div className="previous-work-example-description-bullet">
                  <div className="previous-work-example-description-bullet-icon">
                    <ClockIcon className="icon" />
                  </div>
                  <div className="previous-work-example-description-bullet-text">
                    {text["previous-work-third-duration"][props.language]}
                  </div>
                </div>
                <div className="previous-work-example-description-bullet">
                  <div className="previous-work-example-description-bullet-icon">
                    <UserIcon className="icon" />
                  </div>
                  <div className="previous-work-example-description-bullet-text">
                    {text["previous-work-third-people"][props.language]}
                  </div>
                </div>
              </div>
              <div className="previous-work-example-description-text">
                <div className="previous-work-example-description-text-paragraph">
                  {text["previous-work-third-first-paragraph"][props.language]}
                </div>
                <div className="previous-work-example-description-text-paragraph">
                  {text["previous-work-third-second-paragraph"][props.language]}
                </div>
              </div>
            </div>
          </div>
          <div className="previous-work-testimonial last">
            <div className="previous-work-testimonial-text">
              <div className="previous-work-testimonial-quote">
                {text["previous-work-third-testimonial"][props.language]}
              </div>
              <div className="previous-work-testimonial-name-container">
                <div className="previous-work-testimonial-pic-small">
                  <img src={require("../assets/katy_davies.jpg")}></img>
                </div>
                <div className="previous-work-testimonial-name">
                  <div>Katy Davies,&nbsp;</div>
                  {text["previous-work-third-testimonial-role"][props.language]}
                </div>
              </div>
            </div>
            <div className="previous-work-testimonial-pic">
              <img src={require("../assets/katy_davies.jpg")}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="previous-work-end"></div>
      <div className="contact-container">
        <div className="contact">
          <div className="contact-headline" ref={refContact}>
            {text["contact-headline"][props.language]}
          </div>
          <div className="contact-content">
            <div className="contact-form">
              <div className="contact-form-kind">
                <SelectCustom
                  label={text["contact-form-kind"][props.language]}
                  name="kind"
                  onChange={(e) => handleValueChange("kind", e.value)}
                  options={options}
                  isSearchable={false}
                  placeholder={
                    text["contact-form-kind-placeholder"][props.language]
                  }
                />
              </div>
              <div className="contact-form-name">
                <Input
                  label={text["contact-form-name"][props.language]}
                  name="name"
                  onChange={(e) => handleValueChange("name", e.target.value)}
                  value={contact["name"]}
                />
              </div>
              <div className="contact-form-company">
                <Input
                  label={text["contact-form-company"][props.language]}
                  name="company"
                  onChange={(e) => handleValueChange("company", e.target.value)}
                  value={contact["company"]}
                />
              </div>
              <div className="contact-form-email">
                <Input
                  label={text["contact-form-email"][props.language]}
                  name="email"
                  onChange={(e) => handleValueChange("email", e.target.value)}
                  value={contact["email"]}
                />
              </div>
              <div className="contact-form-message">
                <Textarea
                  label={text["contact-form-message"][props.language]}
                  name="message"
                  onChange={(e) => handleValueChange("message", e.target.value)}
                  value={contact["message"]}
                ></Textarea>
              </div>
              <Button disabled={!buttonEnabled} onClick={(e) => sendEmail(e)}>
                {buttonText}
              </Button>
            </div>
            <div className="contact-card">
              <div className="contact-card-pic">
                <img src={require("../assets/picture_till_2.jpg")}></img>
              </div>
              <div className="contact-card-name">Till Stratmann</div>
              <div className="contact-card-socials">
                <a
                  href="https://www.linkedin.com/in/tstratmann"
                  target="_blank"
                >
                  <div className="contact-card-social">
                    <div className="contact-card-social-icon">
                      <img src={require("../assets/icons/linkedin.png")}></img>
                    </div>
                    <div className="contact-card-social-text">
                      linkedin.com/in/tstratmann
                    </div>
                  </div>
                </a>
                <a
                  href="mailto:till@tillstratmann.com?subject=Inquiry from website"
                  target="_blank"
                >
                  <div className="contact-card-social">
                    <div className="contact-card-social-icon">
                      <EmailIcon className="icon" />
                    </div>
                    <div className="contact-card-social-text">
                      till@tillstratmann.com
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="contact-footer">
            <div>Copyright &copy; 2024 Till Stratmann.</div>
            <div
              className="contact-footer-link"
              onClick={() => navigate(`/${props.language}/imprint/`)}
            >
              {text["imprint"][props.language]}.
            </div>
            <div
              className="contact-footer-link"
              onClick={() => navigate(`/${props.language}/data/`)}
            >
              {text["data-protection"][props.language]}.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageEnglish;
