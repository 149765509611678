import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../components/Button";

const DataProtection = (props) => {
  const text = require(`../Text.json`);
  let navigate = useNavigate();
  const refTop = useRef(null);

  useEffect(() => {
    refTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className="data-container" ref={refTop}>
      <div className="data">
        <Button onClick={() => navigate(`/${props.language}/`)}>
          &larr; {text["imprint-button"][props.language]}
        </Button>
        <h2>{text["data-protection"][props.language]}</h2>
        <div className="data-paragraph">
          <h3>1. Datenschutz auf einen Blick</h3>
          <div className="data-paragraph">Allgemeine Hinweise</div>
          <div className="data-paragraph">
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Datenerfassung auf dieser Website
          </div>
          <div className="data-paragraph">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </div>
          <div className="data-paragraph">
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </div>
          <div className="data-paragraph">Wie erfassen wir Ihre Daten?</div>{" "}
          <div className="data-paragraph">
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch oder
            nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-
            Systeme erfasst. Das sind vor allem technische Daten (z. B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
            betreten.
          </div>
          <div className="data-paragraph">Wofür nutzen wir Ihre Daten?</div>
          <div className="data-paragraph">
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </div>
          <div className="data-paragraph">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </div>
          <div className="data-paragraph">
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu
            sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
            jederzeit an uns wenden.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Analyse-Tools und Tools von Drittanbietern
          </div>
          <div className="data-paragraph">
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen. Detaillierte Informationen zu diesen
            Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
          </div>
        </div>
        <div className="data-paragraph">
          <h3>2. Hosting</h3>
          <div className="data-paragraph">
            Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">Externes Hosting</div>
          <div className="data-paragraph">
            Diese Website wird extern gehostet. Die personenbezogenen Daten, die
            auf dieser Website erfasst werden, werden auf den Servern des
            Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um
            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
            Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
            Daten, die über eine Website generiert werden, handeln. Das externe
            Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren
            potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und
            im Interesse einer sicheren, schnellen und effizienten
            Bereitstellung unseres Online-Angebots durch einen professionellen
            Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
            Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar. Unser(e) Hoster wird bzw. werden Ihre
            Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
            Leistungspflichten erforderlich ist und unsere Weisungen in Bezug
            auf diese Daten befolgen.{" "}
          </div>
          <div className="data-paragraph">
            Wir setzen folgende(n) Hoster ein: Netlify, Inc. 512 2nd Street, Fl
            2 San Francisco, CA 94107
          </div>
        </div>
        <div className="data-paragraph">
          <h3>3. Allgemeine Hinweise und Pflichtinformationen</h3>
          <div className="data-paragraph">Datenschutz</div>
          <div className="data-paragraph">
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z. B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Hinweis zur verantwortlichen Stelle
          </div>
          <div className="data-paragraph">
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist: Till Stratmann Ludwig-Hofer-Str. 15 70192 Stuttgart
            E-Mail: till@tillstratmann.com Verantwortliche Stelle ist die
            natürliche oder juristische Person, die allein oder gemeinsam mit
            anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">Speicherdauer</div>
          <div className="data-paragraph">
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser Website
          </div>
          <div className="data-paragraph">
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
            Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
            Falle einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die
            Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
            DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
            auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
            eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
            wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
            Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
            Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden Absätzen dieser
            Datenschutzerklärung informiert.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Empfänger von personenbezogenen Daten
          </div>
          <div className="data-paragraph">
            Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen
            externen Stellen zusammen. Dabei ist teilweise auch eine
            Übermittlung von personenbezogenen Daten an diese externen Stellen
            erforderlich. Wir geben personenbezogene Daten nur dann an externe
            Stellen weiter, wenn dies im Rahmen einer Vertragserfüllung
            erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z.
            B. Weitergabe von Daten an Steuerbehörden), wenn wir ein
            berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der
            Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die
            Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben
            wir personenbezogene Daten unserer Kunden nur auf Grundlage eines
            gültigen Vertrags über Auftragsverarbeitung weiter. Im Falle einer
            gemeinsamen Verarbeitung wird ein Vertrag über gemeinsame
            Verarbeitung geschlossen.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </div>
          <div className="data-paragraph">
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </div>
          <div className="data-paragraph">
            Wenn die Datenverarbeitung auf grundlage von Art. 6 Abs. 1 Lit. E
            oder F DSGVO erfolgt, haben sie jederzeit das Recht, aus Gründen,
            die sich aus ihrer besonderen Situation ergeben, gegen die
            Verarbeitung ihrer personenbezogenen Daten Widerspruch einzulegen;
            dies gilt auch für ein auf diese bestimmungen gestütztes Profiling.
            Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
            entnehmen sie dieser Datenschutzerklärung. Wenn sie Widerspruch
            einlegen, werden wir ihre betroffenen personenbezogenen Daten nicht
            mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
            Gründe für die Verarbeitung nachweisen, die ihre Interessen, Rechte
            und Freiheiten überwiegen oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            (Widerspruch nach Art. 21 Abs. 1 DSGVO). Wenn sie widersprechen,
            werden ihre personenbezogenen Daten anschliessend nicht mehr zum
            zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
            DSGVO).
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </div>
          <div className="data-paragraph">
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">Recht auf Datenübertragbarkeit</div>
          <div className="data-paragraph">
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Auskunft, Berichtigung und Löschung
          </div>
          <div className="data-paragraph">
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">
            Recht auf Einschränkung der Verarbeitung
          </div>
          <div className="data-paragraph">
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen: Wenn Sie die Richtigkeit
            Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
            benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die
            Dauer der Prüfung haben Sie das Recht, die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn die
            Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
            geschah/geschieht, können Sie statt der Löschung die Einschränkung
            der Datenverarbeitung verlangen. Wenn wir Ihre personenbezogenen
            Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
            Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen,
            haben Sie das Recht, statt der Löschung die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie
            einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss
            eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
            werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
            haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Wenn Sie die Verarbeitung
            Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
            Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung
            oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen
            natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Europäischen Union oder eines
            Mitgliedstaats verarbeitet werden.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">SSL- bzw. TLS-Verschlüsselung</div>
          <div className="data-paragraph">
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS- Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </div>
          <div className="data-paragraph">&nbsp;</div>
          <div className="data-paragraph">Widerspruch gegen Werbe-E-Mails</div>
          <div className="data-paragraph">
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </div>
          <div className="data-paragraph">Quelle: https://www.e-recht24.de</div>
        </div>
      </div>
    </div>
  );
};

export default DataProtection;
