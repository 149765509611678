import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LanguageRedirect = () => {
  var navigate = useNavigate();

  useEffect(() => {
    var language = navigator.language || navigator.userLanguage;
    if (language.startsWith("de")) {
      navigate("/de/");
    } else {
      navigate("/en/");
    }
  }, []);
  return <div>LanguageRedirect</div>;
};

export default LanguageRedirect;
