import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ReactGA from "react-ga4";

import "./App.css";
import Button from "./components/Button";

import HomepageEnglish from "./pages/HomepageEnglish";
import Imprint from "./pages/Imprint";
import DataProtection from "./pages/DataProtection";
import LanguageRedirect from "./pages/LanguageRedirect";

function App() {
  ReactGA.initialize("G-7LP946HMML");
  return (
    <div className="app">
      <div className="app-container">
        <Router>
          <Routes>
            <Route path="/" exact element={<LanguageRedirect />} />
            <Route
              path="/de/"
              exact
              element={<HomepageEnglish language="de" />}
            />
            <Route path="/de/imprint/" element={<Imprint language="de" />} />
            <Route
              path="/de/data/"
              element={<DataProtection language="de" />}
            />
            <Route
              path="/en/"
              exact
              element={<HomepageEnglish language="en" />}
            />
            <Route path="/en/imprint/" element={<Imprint language="en" />} />
            <Route
              path="/en/data/"
              element={<DataProtection language="en" />}
            />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
