import React from "react";
import Select from "react-select";

const SelectCustom = (props) => {
  return (
    <div className="input">
      <div className="input-label">
        <label>{props.label}</label>
      </div>
      <div className="select">
        <Select
          name={props.name}
          defaultValue={props.defaultValue}
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          placeholder={props.placeholder}
          className="react-select-container"
          classNamePrefix="react-select"
          {...props}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#4c31d8 !important" : "#e4e4e4",
              outline: state.isFocused ? "none !important" : "none",
              boxShadow: "none",
              minHeight: "1rem",
              width: "100%",
              maxWidth: "550px",
              fontSize: "1rem",
              lineHeight: "1.6rem",
              padding: "12px 10px",
              marginTop: "-26px",
              paddingTop: "26px",
              borderRadius: "12px",
              cursor: "pointer",
              "&:hover": {
                borderColor: "#4c31d8",
              },
            }),
            inputContainer: (baseStyles, state) => ({
              ...baseStyles,
              margin: "0 !important",
              padding: "0 !important",
              minHeight: "0px",
              lineHeight: 0,
              backgroundColor: "#000000 !important",
            }),
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              margin: "0",
              padding: "0",
              minHeight: "0",
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              margin: "0",
              padding: "0 0 0 2px",
              fontWeight: "200",
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 0,
            }),
            clearIndicator: (baseStyles) => ({
              ...baseStyles,
              paddingTop: 0,
              paddingBottom: 0,
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              paddingTop: 0,
              paddingBottom: 0,
              margin: 0,
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              borderRadius: "12px",
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              padding: "12px 0",
              maxHeight: "250px",
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isSelected
                ? "#4c31d8 !important"
                : state.isFocused
                ? "#e4e4e4"
                : "#ffffff",
              borderRadius: "0",
              borderStyle: "none",
            }),
          }}
        ></Select>
      </div>
    </div>
  );
};

export default SelectCustom;
