import React from "react";

const Textarea = (props) => {
  return (
    <div className="input">
      <div className="input-label">
        <label>{props.label}</label>
      </div>
      <textarea
        disabled={props.disabled}
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={props.value}
      >
        {props.children}
      </textarea>
    </div>
  );
};

export default Textarea;
