import React from "react";

const Input = (props) => {
  return (
    <div
      className={`input${
        props.small ? " small" : props.semismall ? " semismall" : ""
      }`}
    >
      {props.label ? (
        <div className="input-label">
          <label>{props.label}</label>
        </div>
      ) : null}
      <input {...props}></input>
    </div>
  );
};

export default Input;
