import React, { useState } from "react";

import Button from "./Button";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const text = require(`../Text.json`);

  return (
    <div>
      <div className="app-header-container">
        <div className="app-header">
          <div
            className="app-header-name"
            onClick={() => props.executeScroll(props.refTop)}
          >
            <div className="app-header-name-bold">Till Stratmann.</div>{" "}
            <div className="app-header-name-slogan">
              Digital Solutions for Operations.
            </div>
          </div>
          <div className="app-header-space" />
          <div
            className="app-header-menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? (
              <div>&#10005;</div>
            ) : (
              text["header-menu"][props.language]
            )}
          </div>
          <div className="app-header-sections">
            <div
              className="app-header-section"
              onClick={() => props.executeScroll(props.refAboutMe)}
            >
              {text["header-about-me"][props.language]}
            </div>
            <div
              className="app-header-section"
              onClick={() => props.executeScroll(props.refApproach)}
            >
              {text["header-approach"][props.language]}
            </div>
            <div
              className="app-header-section"
              onClick={() => props.executeScroll(props.refPreviousWork)}
            >
              {text["header-previous-work"][props.language]}
            </div>
          </div>
          <div className="app-header-button">
            <Button onClick={() => props.executeScroll(props.refContact)}>
              {text["header-get-in-touch"][props.language]}
            </Button>
          </div>
        </div>
      </div>
      <div className={`menu-container ${menuOpen ? "open" : null}`}>
        <div className="menu">
          <div
            className="menu-item"
            onClick={() => {
              props.executeScroll(props.refAboutMe);
              setMenuOpen(false);
            }}
          >
            {text["header-about-me"][props.language]}
          </div>
          <div
            className="menu-item"
            onClick={() => {
              props.executeScroll(props.refApproach);
              setMenuOpen(false);
            }}
          >
            {text["header-approach"][props.language]}
          </div>
          <div
            className="menu-item"
            onClick={() => {
              props.executeScroll(props.refPreviousWork);
              setMenuOpen(false);
            }}
          >
            {text["header-previous-work"][props.language]}
          </div>
          <div className="menu-item">
            <Button
              onClick={() => {
                props.executeScroll(props.refContact);
                setMenuOpen(false);
              }}
            >
              {text["header-get-in-touch"][props.language]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
