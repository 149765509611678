import React from "react";

const Button = (props) => {
  let accent = "";

  if (props.accent) {
    if (props.disabled) {
      accent = " disabled";
    } else {
      accent = " accent";
    }
  }

  if (props.small) {
    accent = " small";
  }

  if (props.disabled) {
    accent = " disabled";
  }

  return (
    <div>
      <button className={"button" + accent} onClick={props.onClick}>
        <div className="button-content">
          {/* <div className="button-icon">{props.icon}</div> */}
          <div className="button-font">{props.children}</div>
        </div>
      </button>
    </div>
  );
};

export default Button;
